<template>
  <div>
    <div class="banner">
      <warning-tip code="VALIDATE_ACCOUNT_QR"></warning-tip>
      <warning-tip code="VALIDATE_ACCOUNT_RESULT"></warning-tip>
      <main-top title="深度验号" desc="登记成功后24小时查询有效， 超出时间需重新登记" spend="VALIDATE_ACCOUNT_QR,VALIDATE_ACCOUNT_RESULT">
        <!-- 使用搜索框组件 -->
        <div class="input_shouquan">
          <!-- 使用搜索框组件 -->
          <el-button type="primary" size="small" @click="toAuthorization" class="authorization" style="display:none;">
            账号授权</el-button>
          <el-button type="primary" size="default" @click="openCheckUrl" class="authorization">获取验号地址</el-button>

          <InputView :CanYouClick="CanYouClick" ref="searchInput" placeholder-name="请输入买家账号"
            @sendSearchName="getSearchName" style="border:none;flex: 1;margin: 0;"></InputView>
          <p class="input_tip" v-if="inputTip">
            提示：账号正在验证中。。。（验证时间预计1分20秒左右）</p>
        </div>
        <div class="step_tip">

          <div class="step_tip_left">
          <router-link tag="a" target="_blank" :to="{path:'/ValidateAccount_step'}">操作使用说明</router-link>
          </div>
          <p class="step_tip_right">①点击获取验号地址, ②点击生成授权链接, ③点击链接打开淘宝APP扫码,
            ④手机确认授权, ⑤输入淘宝账号即可查询, ⑥请耐心等待1分20秒左右,再点击查询出结果</p>
          <!-- <el-button type="primary" size="small" @click="showQrcode" class="authorization">账号授权（二维码）</el-button> -->
          <!-- <el-button type="primary" size="small" @click="getTransaction">查看交易明细</el-button> -->
        </div>
      </main-top>


      <div class="other-check">
        <!-- <el-button type="primary" size="small" @click="getEvaluate">查看给他人评价</el-button>
        <el-button type="primary" size="small" @click="getsalesReturn">查看退货信息</el-button> -->
      </div>

      <bordure-row title="基本数据" shadow style="margin-bottom:30px;">

        <!-- table -->
        <el-form label-position="left" inline class="account-table-expand" size="small" label-width="150px">
          <!-- <el-form-item label="周单数：">
          <span>{{tableData.order7day}}</span>
        </el-form-item>
        <el-form-item label="月单数：">
          <span>{{tableData.order30day}}</span>
        </el-form-item> -->
          <!-- <el-form-item label="姓名：">
          <span>{{tableData.name}}</span>
        </el-form-item> -->
          <el-form-item label="昵称：">
            <span>{{tableData.userNick}}</span>
          </el-form-item>
          <el-form-item label="账号属性：">
            <span>{{tableData.ylAccountNature}}</span>
          </el-form-item>
          <el-form-item label="年龄阶段：">
            <span>{{tableData.ylAgePhase}}</span>
          </el-form-item>
          <el-form-item label="消费层级：">
            <span>{{tableData.ylConsumeLevel}}</span>
          </el-form-item>
          <!-- <el-form-item label="手机：">
          <span>{{tableData.bindmobile}}</span>
        </el-form-item>
        <el-form-item label="邮箱：">
          <span>{{tableData.mail}}</span>
        </el-form-item> -->
          <el-form-item label="是否认证：">
            <span v-html="tableData.certify"></span>
          </el-form-item>
          <el-form-item label="账号年龄：">
            <span v-if="tableData.regDate">{{new Date().getFullYear()-Number(tableData.regDate.substring(0,4))}}
              年</span>
          </el-form-item>

          <!-- <el-form-item label="会员等级：">
          <span>{{tableData.userLevel}}</span>
        </el-form-item>
        <el-form-item label="信誉评级：">
          <span>{{tableData.creditLevel}}</span>
        </el-form-item>
        <el-form-item label="花呗：">
          <span>{{tableData.huabei}}</span>
        </el-form-item>
        <el-form-item label="收藏店铺：">
          <span>{{tableData.favoriteShop}}</span>
        </el-form-item>
        <el-form-item label="收藏商品：">
          <span>{{tableData.favoriteItem}}</span>
        </el-form-item> -->
          <!-- <el-form-item label="足迹：">
          <span>{{tableData.footPrints}}</span>
        </el-form-item> -->
          <!-- <el-form-item label="IP：">
          <span>{{tableData.ip}}</span>
        </el-form-item>
        <el-form-item label="地址：">
          <span>{{tableData.ipaddress}}</span>
        </el-form-item> -->

          <!-- add -->

          <el-form-item label="浏览购物度：">
            <span :style=" tableData.ylBrowseDegree=='低'? {color:'#ff0000'}:{} ">{{tableData.ylBrowseDegree}}</span>
          </el-form-item>
          <el-form-item label="账号风险值：">
            <span :style=" tableData.ylAccountRisk=='高'? {color:'#ff0000'}:{} ">{{tableData.ylAccountRisk}}</span>
          </el-form-item>
          <el-form-item label="账号权重值：">
            <span :style=" tableData.ylWeight=='低'? {color:'#ff0000'}:{} ">{{tableData.ylWeight}}</span>
          </el-form-item>
          <el-form-item label="信誉等级：">
            <span :style=" tableData.ylCreditLevel=='低'? {color:'#ff0000'}:{} ">{{tableData.ylCreditLevel}}</span>
          </el-form-item>
          <el-form-item label="花呗：">
            <span>{{tableData.huabei}}</span>
          </el-form-item>
          <el-form-item label="综合评分：">
            <span v-if="tableData.ylSynthesisGrade" :style="tableData.ylSynthesisGradeNum<60? {color:'#ff0000'}:{} ">
              {{tableData.ylSynthesisGradeNum}}分
            </span>
          </el-form-item>
          <el-form-item>
            <span slot="label" style="color:#ff0000;">账号评估：</span>
            <p :style="tableData.ylSynthesisGradeNum<60? {color:'#ff0000'}:{} ">
              {{tableData.ylSynthesisGrade}}
              <span v-if="tableData.ylSynthesisGrade=='极差'">:降权号（高风险账号）</span>
              <span v-else-if="tableData.ylSynthesisGrade=='差'">:低权重账号（不建议使用）</span>
              <span v-else-if="tableData.ylSynthesisGrade=='及格'">:正常账号（注意控制单量）</span>
              <span v-else-if="tableData.ylSynthesisGrade=='良好'">:高权重账号（1单抵3单）</span>
              <span v-else-if="tableData.ylSynthesisGrade=='优秀'">:极致账号（1单抵5单）</span>
              <span v-else></span>
            </p>
          </el-form-item>
          <el-form-item label="买家等级：">
            <div v-if="tableData.newBuyerCreditPic">
              <img :src="tableData.newBuyerCreditPic" height="15" />
            </div>
          </el-form-item>

        </el-form>
      </bordure-row>

      <!-- <div class="tableTitle">购买数据</div>
      <el-form label-position="left" inline class="account-table-expand" size="small" label-width="150px">
        <el-form-item label="待付款：">
          <span>{{tableData.order2Pay}}</span>
        </el-form-item>
        <el-form-item label="待发货：">
          <span>{{tableData.order2Deliver}}</span>
        </el-form-item>
        <el-form-item label="待收货：">
          <span>{{tableData.order2Receive}}</span>
        </el-form-item>
        <el-form-item label="待评价：">
          <span>{{tableData.order2Rate}}</span>
        </el-form-item>
        <el-form-item label="退货(3个月内)：">
          <span>{{tableData.order2Refund}}</span>
        </el-form-item>
        <el-form-item label="退货率(最近30天)：">
          <span>{{tableData.tuikuanlv}}</span>
        </el-form-item>
        <el-form-item label="淘龄(天数)：">
          <span>{{tableData.registerDays}}</span>
        </el-form-item>
        <el-form-item label="成长值：">
          <span>{{tableData.vipCount}}</span>
        </el-form-item>
        <el-form-item label="购物评级：">
          <span>{{tableData.starLevel}}</span>
        </el-form-item>
        <el-form-item label="天猫积分：">
          <span>{{tableData.money}}</span>
        </el-form-item>
        <el-form-item label="淘金币：">
          <span>{{tableData.Taojinbi}}</span>
        </el-form-item>
        <el-form-item label="总消费金额：">
          <span>{{tableData.OrderAmount}}</span>
        </el-form-item>
        <el-form-item label="总消费单数：">
          <span>{{tableData.OrderCount}}</span>
        </el-form-item>

        <el-form-item label="月均消费：">
          <span>{{tableData.avgMonthBuy}}</span>
        </el-form-item>
      </el-form> -->
      <!-- <div class="tableTitle">权重数据</div>
      <el-form label-position="left" inline class="account-table-expand" size="small" label-width="150px">
        <el-form-item label="声纹密保：">
          <span>{{tableData.VOICE_PRINT_ENCRYPT}}</span>
        </el-form-item>
        <el-form-item label="支付宝身份验证：">
          <span>{{tableData.ALIPAY_AUTHENTICATION}}</span>
        </el-form-item>
        <el-form-item label="支付宝实名认证：">
          <span>{{tableData.ALIPAY_REAL_NAME_AUTHENTICATION}}</span>
        </el-form-item>
        <el-form-item label="淘宝绑定手机：">
          <span>{{tableData.TAOBAO_BANDING_MOBILE_PHONE}}</span>
        </el-form-item>
        <el-form-item label="授权芝麻信用：">
          <span>{{tableData.ZHIMA_CREDIT_AUTHENTICATION}}</span>
        </el-form-item>
        <el-form-item label="支付宝实人认证：">
          <span>{{tableData.REAL_PEOPLE_VERIFY}}</span>
        </el-form-item>
        <el-form-item label="极速退款：">
          <span>{{tableData.QUICK_REFUND}}</span>
        </el-form-item>
        <el-form-item label="品质保证险：">
          <span>{{tableData.QUALITY_GUARANTEE_INSURANCE}}</span>
        </el-form-item>
        <el-form-item label="极速维权：">
          <span>{{tableData.DISPUTE_PROCESS_PRIORITY}}</span>
        </el-form-item>
        <el-form-item label="电话服务优先：">
          <span>{{tableData.TELEPHONE_ANSWERING_PRIORITY}}</span>
        </el-form-item>
        <el-form-item label="免举证退换货：">
          <span>{{tableData.FREE_PROOF_OF_EXCHANGE}}</span>
        </el-form-item>
        <el-form-item label="闪电退货：">
          <span>{{tableData.QUICK_SALES_RETURN}}</span>
        </el-form-item>
        <el-form-item label="APASS服务：">
          <span>{{tableData.APASS_SERVICE}}</span>
        </el-form-item>
      </el-form>
      <div class="tableTitle">淘气值</div>
      <el-form label-position="left" inline class="account-table-expand" size="small" label-width="150px">
        <el-form-item label="购物分：">
          <span>{{tableData.tradeScore}}</span>
        </el-form-item>
        <el-form-item label="奖励分：">
          <span>{{tableData.bonusScore}}</span>
        </el-form-item>
        <el-form-item label="基础分：">
          <span>{{tableData.basicScore}}</span>
        </el-form-item>
        <el-form-item label="本期购物：">
          <span>{{tableData.buyMoneyConvertCur}}</span>
        </el-form-item>
        <el-form-item label="购物奖励分：">
          <span>{{tableData.tradeBonusScore}}</span>
        </el-form-item>
        <el-form-item label="互动奖励分：">
          <span>{{tableData.interactBonusScore}}</span>
        </el-form-item>
        <el-form-item label="上期购物：">
          <span>{{tableData.buyMoneyConvertLast}}</span>
        </el-form-item>
        <el-form-item label="亲情奖励分：">
          <span>{{tableData.qqhBonusScore}}</span>
        </el-form-item>
      </el-form> -->
      <!-- <div class="tableTitle">信誉明细</div>
      <el-form label-position="left" inline class="account-table-expand" size="small" label-width="150px">
        <div class="credit-box">
          <div class="credit-time">
            <span>最近1周</span>
            <span>最近1月</span>
            <span>最近6月</span>
          </div>
          <el-form-item label="好评" class="credit-item-content">
            <div class="credit-item">
              <span>{{tableData.good7}}</span>
              <span>{{tableData.good30}}</span>
              <span>{{tableData.good210}}</span>
            </div>
          </el-form-item>
          <el-form-item label="中评" class="credit-item-content">
            <div class="credit-item">
              <span>{{tableData.neutral7}}</span>
              <span>{{tableData.neutral30}}</span>
              <span>{{tableData.neutral210}}</span>
            </div>
          </el-form-item>
          <el-form-item label="差评" class="credit-item-content">
            <div class="credit-item">
              <span>{{tableData.bad7}}</span>
              <span>{{tableData.bad30}}</span>
              <span>{{tableData.bad210}}</span>
            </div>
          </el-form-item>
        </div>
      </el-form> -->

      <!-- <div id="container" style="min-width: 400px; max-width: 600px; height: 400px; margin: 0 auto"></div> -->

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>


      <el-dialog title="交易明细" :visible.sync="dealPopup" width="1000px" @close="dealClose">
        <el-table :data="dealData" size="small" stripe class="deal-table" style="height:500px;overflow: auto;">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="deal-table-expand" v-for="(item,index) in scope.row.orders"
                :key="index">
                <el-form-item label="商品Id">
                  <p class="deal-table-expand-title">{{ item.itemId }}</p>
                </el-form-item>
                <el-form-item label="Sku信息">
                  <p class="deal-table-expand-title">{{ item.skuText }}</p>
                </el-form-item>
                <el-form-item label="商品原价">
                  <p class="deal-table-expand-title">{{ item.original }}</p>
                </el-form-item>
                <el-form-item label="商品数量">
                  <p class="deal-table-expand-title">{{ item.quantity }}</p>
                </el-form-item>
                <el-form-item label="异常订单">
                  <p class="deal-table-expand-title">{{ item.warning }}</p>
                </el-form-item>
                <el-form-item label="商品图片">
                  <div style="width:50px;height:50px;">
                    <el-image style="width: 50px; height: 50px" :src="item.pic" :fit="cover"></el-image>
                  </div>
                </el-form-item>
                <el-form-item label="商品标题">
                  <p class="deal-table-expand-title">{{ item.title }}</p>
                </el-form-item>
                <el-form-item label="商品下单价">
                  <p class="deal-table-expand-title">{{ item.realTotal }}</p>
                </el-form-item>
                <el-form-item label="子订单号">
                  <p class="deal-table-expand-title">{{ item.tid }}</p>
                </el-form-item>
                <el-form-item label="SkuId">
                  <p class="deal-table-expand-title">{{ item.skuId }}</p>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="sellerNick" label="商家昵称"></el-table-column>
          <el-table-column prop="usernick" label="昵称"></el-table-column>
          <el-table-column prop="tid" label="订单号"></el-table-column>
          <el-table-column prop="status" label="交易状态"></el-table-column>
          <el-table-column prop="actualFee" label="支付金额"></el-table-column>
          <el-table-column prop="payType" label="付款方式"></el-table-column>
          <el-table-column prop="OrderType" label="订单平台"></el-table-column>
          <el-table-column prop="created" label="下单时间"></el-table-column>
          <el-table-column prop="postFee" label="	邮费"></el-table-column>
          <el-table-column prop="rateOrder" label="评价状态"></el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog title="评价信息" :visible.sync="evaluatePupop" width="1000px" @close="evaluateClose">
        <el-table :data="evaluateData" stripe size="small" style="height:500px;overflow: auto;">
          <el-table-column prop="userNick" label="昵称"></el-table-column>
          <el-table-column prop="sellerNick" label="卖家昵称"></el-table-column>
          <el-table-column prop="itemTitle" label="商品标题" show-overflow-tooltip></el-table-column>
          <el-table-column prop="price" label="商品价格"></el-table-column>
          <el-table-column prop="rate" label="评价"></el-table-column>
          <el-table-column prop="comment" label="	评论内容"></el-table-column>
          <el-table-column prop="photos" label="评论图片"></el-table-column>
          <el-table-column prop="date" label="评论时间"></el-table-column>
          <el-table-column prop="ifad" label="	异常评价信息" width="100"></el-table-column>
          <el-table-column prop="appendComment" label="追评内容"></el-table-column>
          <el-table-column prop="appendPhotos" label="	追评图片"></el-table-column>
          <el-table-column prop="appendDate" label="追评时间"></el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog title="退货信息" :visible.sync="salesReturnPupop" width="1000px" @close="salesReturnClose">
        <el-table :data="salesReturnData" stripe size="small" style="height:500px;overflow: auto;">
          <el-table-column prop="userNick" label="昵称"></el-table-column>
          <el-table-column prop="shopname" label="店铺名称"></el-table-column>
          <el-table-column prop="shopid" label="店铺Id"></el-table-column>
          <el-table-column prop="itemtitle" label="商品标题" show-overflow-tooltip></el-table-column>
          <el-table-column prop="itemsku" label="颜色分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="amount" label="退款金额"></el-table-column>
          <el-table-column prop="status" label="退款状态">
            <template slot-scope="scope">
              <div v-html="scope.row.status"></div>
            </template>
          </el-table-column>
          <el-table-column prop="reqdate" label="申请时间"></el-table-column>
          <el-table-column prop="tid" label="	订单Id"></el-table-column>
          <el-table-column prop="rid" label="	退款单号" width="100"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <div>
                <el-image style="width: 50px; height: 50px" :src="scope.row.itemimg" :fit="cover"
                  :preview-src-list="previewcheak(scope.row.itemimg)"></el-image>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>


      <!-- 二维码 -->
      <el-dialog title="扫码授权" :visible.sync="qrcodePupop" width="500px">
        <div style="text-align:center;padding:20px 0;">
          <div id="qrcode" ref="qrcode" class="qrcode"></div>
        </div>
      </el-dialog>

      <el-dialog title="扫码授权" :visible.sync="iframePupop" :destroy-on-close="true" top="10vh" width="640px"
        @opened="openedIframeWay">
        <iframe v-if="iframebox" :src="iframeUrl" frameborder="0" style="width:100%;height:550px;">
          <p>你的浏览器不支持iframe页面嵌套，请点击这里访问页面内容。</p>
        </iframe>
        <div style="text-align: center;">
          <el-button type="primary" size="default" @click="toAuthorization">刷新二维码</el-button>
        </div>

      </el-dialog>

      <el-dialog title="获取验号地址" :visible.sync="checkPupop"  class="check-dialog">
        <div>
          <div class="check_item">
            <div class="check_item_label">二维码</div>
            <div class="check_item_content">
              <div class="check_item_code">
                <qrcode :value="tbAuthorizationQrCode" :options="{ size: 190 }"></qrcode>
                <p>使用手机扫一扫获取验号权限</p>
              </div>
            </div>
          </div>
          <div class="check_item">
            <div class="check_item_label">链接地址</div>
            <div class="check_item_content"><a :href="tbAuthorizationUrl" target="_blank">{{tbAuthorizationUrl}}</a>
              &nbsp;&nbsp;点击打开链接</div>
          </div>
        </div>
      </el-dialog>

    </div>
  </div>
</template>
<script>
  import InputView from "../public/InputView.vue";
  import Introduce from "../public/Introduce.vue";
  import QRCode from 'qrcodejs2'
  import Qrcode from "@xkeshi/vue-qrcode";
  // import Highcharts from 'highcharts/highstock';
  // import HighchartsMore from 'highcharts/highcharts-more';
  // import Exporting from 'highcharts/modules/exporting.js'
  // HighchartsMore(Highcharts);
  // Exporting(Highcharts);
  import {
    userCheck,
    toQrCode,
    // transactionCheck,
    // evaluateCheck,
    // salesReturnCheck
  } from "@/request/api"; //这里是引入请求
  export default {
    data() {

      return {
        CanYouClick: true,
        tableData: {}, //总列表
        dimension: [1, 1, 0.5, 1, 1], //维度
        reputationList: [{
            good: '好评',
            good7: '',
            good30: '',
            good210: ''
          },
          {
            neutral: '中评',
            neutral7: '',
            neutral30: '',
            neutral210: ''
          },
          {
            bad: '差评',
            bad7: '',
            bad30: '',
            bad210: ''
          },
        ], //信誉明细
        checkPupop: false, //验号地址弹窗
        originUrl: '',
        tbAuthorizationUrl: '',
        tbAuthorizationQrCode: '',
        chart: {},
        options: {
          chart: {
            polar: true,
            type: 'area'
          },
          title: {
            text: "信誉评级维度"
          },
          pane: {
            size: '100%'
          },
          // subtitle: {
          //   text: "账户信息"
          // },
          xAxis: {
            categories: ['账户信息', '购物历史', '购物信用', '购物合规', '评价历史'],
            tickmarkPlacement: 'on',
            lineWidth: 0
          },
          credits: {
            text: "",
            href: ""
          },
          exporting: {
            enabled: false
          },
          yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0,
            max: 1
          },
          tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:*100f}</b><br/>'
          },
          series: [{
            name: "急速验号淘宝帐号",
            data: [1, 1, 1, 1, 1],
            pointPlacement: 'on'
          }, ],
        },

        inputTip: false, //授权提示

        dealPopup: false, //交易弹窗
        dealData: [], //交易列表

        evaluatePupop: false, //评价弹窗
        evaluateData: [], //评价列表

        salesReturnPupop: false, //退货弹窗
        salesReturnData: [], //退货列表

        iframePupop: false, //iframe弹窗
        iframebox: false,
        iframeUrl: '', //iframe  url

        qrcodePupop: false, //二维码弹窗
        introduce: [{
            title: "使用方法",
            icon: "&#xe60a;",
            content: [

              "1、点击账号授权",
              "2、点击生成授权码",
              "3、手机打开淘宝APP扫码",
              "4、手机确认授权",
              "5、输入淘宝账号即可查询",
              "6、请耐心等待1分20秒左右,再点击查询出结果",
            ],
          },
          {
            title: "功能详解",
            icon: "&#xe60c;",
            content: [
              "消费层级：根据账号最近30天平均消费水平",
              "浏览购物度：根据账号最近30天浏览淘宝是否频繁",
              "账号风险值：根据账号最近15天以及最近一个月成交笔数综合判断是否交易频繁存在风险",
              "信誉等级：根据账号账号最近15天待收货记录以及最近一个月给商家打出的差评率判断",
              "账号权重值：根据账号淘气值、芝麻信用分、账号等级、花呗等级、急速退款、88会员等及多个维度综合判断",
              "综合评分：综合评分将根据账号账号风险值、信誉等级、账号权重值综合三大维度综合判断账号",
              "账号评估：根据上述分值判断账号补单得到的权重效果"
            ],
          },
        ], //介绍相关数组
      };
    },
    components: {
      InputView,
      Introduce,
      Qrcode,
      // Highcharts,
    },
    created() {
      this.originUrl = window.location.origin
      if (this.$store.state.token != null) {
        // this.getQueryOrderList();
      }
    },
    mounted() {
      // this.moreChart()
    },
    methods: {
      openedIframeWay() {
        this.iframebox = true
      },
      hidden(str) {
        let len = str.length - parseInt(str.length / 2)
        let xing = ''
        for (let i = 0; i < len; i++) {
          xing += '*'
        }
        return str.substring(0, parseInt(str.length / 2)) + xing
      },
      cleanImg(str) {
        if (str) {
          return str.replace("http://img.taoyanhao.com/", "//storage.shujufish.com/yunlu-share/taocece/")
        }
        return str;
      },
      //   子传父的方法
      getSearchName(value) {
        this.CanYouClick = false; //不允许再点击搜索
        this.tableData = {}
        this.$store.commit("alterJumpFlag", true);
        this.$store.commit("alterLoadContent", "正在该账号的相关数据");
        userCheck({
          nick: value
        }).then((data) => {
          this.inputTip = false;
          this.tableData = data.data
          this.tableData.userNick = this.hidden(this.tableData.userNick)
          this.tableData.certify = this.cleanImg(this.tableData.certify)
          let arr = []
          arr.push(Number(data.data.account_info))
          arr.push(Number(data.data.buy_record))
          arr.push(Number(data.data.buy_credit))
          arr.push(Number(data.data.buy_compliance))
          arr.push(Number(data.data.buy_feed))
          this.dimension = arr
          this.options.series[0].data = this.dimension

        }).catch((err) => {
          if (err.message.indexOf('已授权') != -1) {
            // 接口请求失败,【欧集红】已授权，正在读取信息，请稍后再查！
            this.inputTip = true;
          } else {
            this.inputTip = false;
          }
        }).finally(() => {
            this.$store.commit("alterJumpFlag", false); //关掉遮罩层
            this.CanYouClick = true; //允许再次搜索
        });
      },

      // 授权跳转
      toAuthorization() {
        this.iframeUrl = ''
        toQrCode().then((data) => {
          this.iframeUrl = data.data.url
          this.iframePupop = true
        })
      },

      // 查看交易信息
      // getTransaction() {
      //   let value = this.$refs.searchInput.sceneName;
      //   transactionCheck({
      //     nick: value
      //   }).then((data) => {
      //     this.dealPopup = true;
      //     this.dealData = data.data
      //   })
      // },

      //关闭交易信息
      dealClose() {
        this.dealPopup = false;
      },

      // 获取评价信息
      // getEvaluate() {
      //   let value = this.$refs.searchInput.sceneName;
      //   evaluateCheck({
      //     nick: value
      //   }).then((data) => {
      //     this.evaluateData = data.data;
      //     this.evaluatePupop = true;
      //   })
      // },

      // 评价弹窗关闭
      evaluateClose() {
        this.evaluatePupop = false;
      },

      // 查看购物车信息
      // getsalesReturn() {
      //   let value = this.$refs.searchInput.sceneName;
      //   salesReturnCheck({
      //     nick: value
      //   }).then((data) => {
      //     this.salesReturnPupop = true;
      //     this.salesReturnData = data.data
      //   })
      // },

      salesReturnClose() {
        this.salesReturnPupop = false;
      },

      previewcheak(img) {
        let imgArr = []
        imgArr.push(img)
        return imgArr
      },

      qrcode(url) {
        let qrcode = new QRCode('qrcode', {
          width: 300, // 设置宽度，单位像素
          height: 300, // 设置高度，单位像素
          text: url // 设置二维码内容或跳转地址
        })
      },

      showQrcode() {
        toQrCode().then((data) => {
          this.qrcodePupop = true;
          this.$nextTick(() => {
            document.getElementById('qrcode').innerHTML = "";
            this.qrcode(data.data.url)
          })
        })
      },

      refreshCode() {
        this.iframeUrl = ''
      },

      openCheckUrl() {
        this.tbAuthorizationUrl = "";
        this.tbAuthorizationQrCode = "";
        //获取授权地址页面
        this.$store.commit("alterJumpFlag", true);
        this.$store.commit("alterLoadContent", "正在获取授权地址");
        toQrCode().then((data) => {
          this.checkPupop = true;
          this.tbAuthorizationUrl = data.data.url;
          this.tbAuthorizationQrCode = data.data.url;
        }).finally(() => {
          this.$store.commit("alterJumpFlag", false); //关掉遮罩层
        });

      }

      // moreChart() {
      //   console.log(111)
      //   // 初始化 Highcharts 图表
      //   this.chart = new Highcharts.Chart("container", this.options);
      // },

      // reDraw() {
      //   this.chart.series[0].setData(this.options.series[0].data)
      //   this.chart.redraw()
      // }

    },
    // watch: {
    //   options: {
    //     handler: function () {
    //       console.log('ChartsDataUpdate')
    //       this.reDraw()
    //     },
    //     deep: true
    //   }
    // }
  };
</script>

<style lang="less" scoped>
  .head-tips {
    color: #999;
    font-size: 12px;
    margin-left: 40px;
  }

  .multipleSearch {
    padding: 80px 200px 40px;
    text-align: center;
  }

  .multipleSearchBottom {
    padding: 0 80px 100px;
    text-align: center;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 300px;
  }

  .authorization {
    // float: right;
    margin-right: 20px;
  }

  .credit-box {
    margin-right: 300px;
    margin-top: 10px;
  }

  .credit-time {
    font-size: 14px;
    padding-left: 150px;
    display: flex;
    justify-content: space-between;
    color: #99a9bf;
    margin-bottom: 10px;
  }

  .credit-item {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      display: inline-block;
      width: 50px;
    }
  }

  .other-check {
    margin-bottom: 20px;
  }

  .qrcode {
    display: inline-block;
  }
</style>

<style lang="less">
  .account-table-expand {
    font-size: 0;
    width: 1000px;
    margin: 0 auto 20px;
  }

  .account-table-expand label {
    width: 90px;
    color: #000;
  }

  .account-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .account-table-expand .credit-item-content {
    width: 100%;

    .el-form-item__content {
      width: calc(100% - 150px);
    }
  }

  .deal-table::before {
    display: none;
  }

  .deal-table-expand {
    font-size: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;

    &:last-child {
      border-bottom: none;
    }
  }

  .deal-table-expand-title {
    width: 300px;
  }

  .deal-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .deal-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .input_shouquan {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid #ededed;
    padding: 30px 0 0 0;
    margin-top: 20px;
    justify-content: center;
    position: relative;

    .input_tip {
      position: absolute;
      left: 170px;
      bottom: -20px;
      font-size: 12px;
      color: #fd0000;
    }
  }

  .step_tip {
    display: flex;
    max-width: 1146px;
    margin: 0 auto;
    align-items: center;

    .step_tip_left {
      width: 126px;
      margin: 0 20px 0 10px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      a{
      color: #409eff;
      }
    }

    .step_tip_right {
      flex: 1;
      font-size: 14px;
      margin: 20px 0;
      color: red;
      line-height: 24px;
    }
  }

  .check_item {
    display: flex;
    margin-bottom: 30px;

    .check_item_label {
      width: 80px;
    }

    .check_item_content {
      flex: 1;

      .check_item_code {
        display: inline-block;
        text-align: center;
      }

      a {
        color: #409EFF;
      }
    }
  }

  .check-dialog .el-dialog{
    width: 50%;
  }

 @media screen and (max-width: 640px){
   .check-dialog .el-dialog{
    width: 90%;
   }


  }

</style>