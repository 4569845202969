<template>
  <!-- 搜索 -->
  <div class="search-wrapper-box">
    <div class="search-input-wrap">
      <el-input
        v-model.trim="sceneName"
        class="search-input elc-list-input"
        size="mini"
        :placeholder="placeholderName"
        @keyup.native.enter="searchCon"
        @change="changeHandle"
        clearable
      >
        <!-- <i slot="prefix" class="el-input__icon el-icon-search search-icon" /> -->
      </el-input>
      <div class="search-input-result" v-if="$scopedSlots.result">
        <slot name="result"></slot>
      </div>
    </div>

    <el-row>
      <!-- <div type="primary" class="search-btn" @click="searchCon">搜索</div> -->
      <el-button
        type="primary"
        size="default"
        class="search-btn"
        @click="searchCon"
        >搜索</el-button
      >
    </el-row>
  </div>
</template>
<script>
export default {
  name: "SearchInput",
  props: {
    placeholderName: {
      //提示输入的内容
      type: String,
      default: "",
    },
    CanYouClick: {
      //是否可以点击按钮
      type: Boolean,
      default: "",
    },
    extractProductId: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sceneName: "",
    };
  },
  created() {
    this.sceneName = "";
  },
  methods: {
    searchCon() {
      if (!this.CanYouClick) {
        return;
      }

      let inputValue = this.sceneName;
      if (!inputValue) {
        this.$message.error("请输入内容再操作");
        return;
      }

      //提取产品ID
      if (this.extractProductId) {
        let numPattern = /^\d+$/;
        inputValue = inputValue.trim();
        if (!numPattern.test(inputValue)) {
          let params = this.urlParamHash(inputValue);
          inputValue = params["id"];
        }

        if (!inputValue || !numPattern.test(inputValue)) {
          this.$message.error("请输入正确的商品ID或商品地址");
          return;
        }
      }

      this.$store.commit("alterJumpFlag", true);
      this.$emit("sendSearchName", inputValue);
    },

    urlParamHash(url) {
      var params = [],
        h;
      var hash = url.slice(url.indexOf("?") + 1).split("&");
      for (var i = 0; i < hash.length; i++) {
        h = hash[i].split("=");
        params.push(h[0]);
        params[h[0]] = h[1];
      }
      return params;
    },

    changeHandle(){
      this.$emit("change", this.sceneName);
    }
  },
};
</script>
<style lang="less" scoped>
.search-wrapper-box {
  border-top: 1px solid #ededed;
  padding: 20px;
  display: flex;
  align-items: center;

  .search-input-wrap {
    position: relative;
    flex:1;
  }

  .search-input-result {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0px;
    right: 0px;
    background-color: #f4f4f4;
    border:solid 2px #dfe6ec;
    border-top-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    // box-shadow: 0px 6px 9px rgba(0,0,0,0.2);
    line-height: 2;
    padding: 6px 8px;
    margin-top: -2px;
  }

  // 搜索框样式覆盖
  .search-input {
    ::v-deep .el-input__inner {
      color: #333;
      height: 40px;
      line-height: 40px;
      background: #ffffff;
      border: 2px solid #dfe6ec;
      font-size: 14px;
    }
  }

  .search-btn {
    width: 100px;
    height: 40px;
    line-height: 5px;
    font-size: 14px;
    letter-spacing: 0;
    margin-left: 30px;
  }
}
</style>