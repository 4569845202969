<template>
  <div>
    <!-- 讲解文案区域 -->
    <div class="introduce_box shadow_box">
      <!-- 功能介绍 -->
      <div class="introduce-title-box">
        <span>常见问题</span>
      </div>

      <div v-for="(item, index) in introduce" :key="index" class="introduce_item">
        <div>
          <!-- <i class="iconfont" style="color: #a3a9c1; margin-right: 10.8px" v-html="item.icon"></i> -->
          <div class="secondTitle">
            <img class="angle"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAPCAYAAADHw76AAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQ5OTYyOTFGQzRFODExRUI5NzQ1RjU5MDM1Q0E1MTZDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQ5OTYyOTIwQzRFODExRUI5NzQ1RjU5MDM1Q0E1MTZDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDk5NjI5MURDNEU4MTFFQjk3NDVGNTkwMzVDQTUxNkMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDk5NjI5MUVDNEU4MTFFQjk3NDVGNTkwMzVDQTUxNkMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz70wisNAAAAWklEQVR42mLM2/v/DAMDgzEDGmAC4tkMWABIYikQf8Em8QUqiSHBgM04mMRZKMaQwNCFLIHiCGQJFEcwodk5G5cEyAFnsEmAwBxcEmBHYJMAOwKbBNgRAAEGANDME7NjoH0wAAAAAElFTkSuQmCC"
              alt="">
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="secondTitleBottom">
          <p v-for="(item_in, index_in) in item.content" :key="index_in">
            {{ item_in }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "introduce",
    props: {
      introduce: {
        type: Array,
        default: [],
      },
    },
  };
</script>
<style lang="less" scoped>
  .introduce_box {
    background: #fff;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    font-size: 12px;
    color: #525252;
    margin-top: 30px;
    overflow: hidden;
    box-sizing: border-box;

    .introduce-title-box {
      margin-bottom: 20px;
      display: inline-block;

      span {
        &:first-child {
          font-size: 14px;
          font-weight: 400;
          color: #333;
        }
      }
    }

    .introduce_item {
      width: 100%;
      background: #ecf4ff;
      border-radius: 6px;
      padding-bottom: 10px;
      box-sizing: border-box;
      z-index: 9;
    }
  }

  .secondTitle {
    background: linear-gradient(90deg, rgb(159, 212, 255), rgb(106, 177, 254));
    margin-top: 10px;
    display: inline-block;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    position: relative;
    margin-left: -6px;
    line-height: 28px;
    border-radius: 15px 15px 15px 0;
    margin-bottom: 10px;
    box-sizing: border-box;

    .angle {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 0;
      bottom: -15px;
    }
  }

  .secondTitleBottom {
    margin: 8px 0 0 26px;
    line-height: 24px;
    font-size: 12px;
    color: #333333;
    letter-spacing: 0;
    margin-bottom: 16px;
  }

        /* 低阴影 */
  .shadow_box{
    box-shadow: 0 5px 15px 1px hsla(0, 0%, 75%, .2);
  }
</style>